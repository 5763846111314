.box{
    background:linear-gradient(rgba(247, 242, 242, 0.357),transparent);
    background-color:rgb(225, 159, 25) /*this your primary color*/
  }

/* Add this CSS to your stylesheet or component's styles */
.news-item {
  /* Add any other styles you need for each news item */
}

.content-container {
  max-width: 300px; /* Set the maximum width for the content */
  word-wrap: break-word; /* Wrap long words to prevent overflow and go to a new line */
  overflow-wrap: break-word; /* Alternative property for browser compatibility */
  white-space: pre-wrap; /* Preserve line breaks within the content */
}

.content-container p {
  margin: 0; /* Remove any default margin for the paragraph */
}
